import React from "react"
import c from "classnames"
import { useSubscribeOverlayStore } from "src/state/subscribe-overlay"
import { useMainStore } from "src/state/main"
import { Formik, Form, Field, ErrorMessage } from "formik"
import PillButton from "src/components/pill-button"
import Markup from "src/components/markup"
import { selectChevron, subscribeOverlayBgCircle, overlayCloseBtn } from "src/components/svgs"

const SubscribeOverlay = () => {
  const { title, form, shouldShowSubscribeOverlay, setShouldShowSubscribeOverlay, mktoFormId } =
    useSubscribeOverlayStore()

  const { isBrowser } = useMainStore()

  const onSubmit = (values, { setSubmitting }) => {
    if (!isBrowser || !window.MktoForms2) return
    const mktoForm = window.MktoForms2.allForms().find(
      (f) => `mktoForm_${f.getId()}` === mktoFormId
    )
    const form = document.querySelector(`#${mktoFormId}`)
    if (!mktoForm) return
    Object.keys(values).forEach((key) => {
      const value = values[key]
      switch (key) {
        case "firstName":
          form.querySelector("#FirstName").value = value
          break
        case "lastName":
          form.querySelector("#LastName").value = value
          break
        case "companyName":
          form.querySelector("#Company").value = value
          break
        case "jobTitle":
          form.querySelector("#Title").value = value
          break
        case "email":
          form.querySelector("#Email").value = value
          break
        case "country":
          form.querySelector("#Country").value = value
          break
        default:
      }
    })
    mktoForm.onSuccess(() => false)
    mktoForm.submit()
  }

  const validate = (values) => {
    const errors = {}
    Object.keys(values).forEach((key) => {
      const value = values[key]
      switch (key) {
        case "firstName":
          if (!value) return (errors.firstName = form.requiredText)
          break
        case "lastName":
          if (!value) return (errors.lastName = form.requiredText)
          break
        case "companyName":
          if (!value) return (errors.companyName = form.requiredText)
          break
        case "jobTitle":
          if (!value) return (errors.jobTitle = form.requiredText)
          break
        case "email":
          if (!value) return (errors.email = form.requiredText)
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value))
            return (errors.email = `${form.invalidText} ${form.emailPlaceholder}`)
          break
        case "country":
          if (!value) return (errors.country = form.requiredText)
          break
        default:
      }
    })
    return errors
  }

  return (
    <div className={c("subscribe-overlay", shouldShowSubscribeOverlay && "visible")}>
      <div
        className="bg"
        onClick={() => setShouldShowSubscribeOverlay(false)}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
      >
        <Markup>{subscribeOverlayBgCircle}</Markup>
      </div>
      <div className="content">
        <h3>{title}</h3>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            companyName: "",
            jobTitle: "",
            email: "",
            message: "",
            country: "",
          }}
          validate={validate}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValid, dirty, values }) => (
            <Form className="form">
              <div className="row">
                <div className="field-group">
                  <Field type="text" name="firstName" placeholder={form.firstNamePlaceholder} />
                  <ErrorMessage className="error" name="firstName" component="div" />
                </div>
                <div className="field-group">
                  <Field type="text" name="lastName" placeholder={form.lastNamePlaceholder} />
                  <ErrorMessage className="error" name="lastName" component="div" />
                </div>
              </div>
              <div className="row">
                <div className="field-group">
                  <Field type="text" name="companyName" placeholder={form.companyNamePlaceholder} />
                  <ErrorMessage className="error" name="companyName" component="div" />
                </div>
                <div className="field-group">
                  <Field type="text" name="jobTitle" placeholder={form.jobTitlePlaceholder} />
                  <ErrorMessage className="error" name="jobTitle" component="div" />
                </div>
              </div>
              <div className="field-group">
                <Field type="email" name="email" placeholder={form.emailPlaceholder} />
                <ErrorMessage className="error" name="email" component="div" />
              </div>
              <div className="field-group">
                <div className="select">
                  <p className="select-value">{values.country || form.countryPlaceholder}</p>
                  <div className="select-chevron">
                    <Markup>{selectChevron}</Markup>
                  </div>
                  <Field as="select" name="country">
                    {form.countries.map(({ name, gdpr }) => (
                      <option key={name} value={name}>
                        {name}
                      </option>
                    ))}
                  </Field>
                </div>
                <ErrorMessage className="error" name="country" component="div" />
              </div>
              <button type="submit" disabled={isSubmitting}>
                <PillButton className="white-red">
                  {isSubmitting ? form.submitSuccessText : form.submitButtonText}
                </PillButton>
              </button>
            </Form>
          )}
        </Formik>
      </div>
      <div
        className="close-btn"
        onClick={() => setShouldShowSubscribeOverlay(false)}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
      >
        <Markup>{overlayCloseBtn}</Markup>
      </div>
    </div>
  )
}

export default SubscribeOverlay
