import create from "zustand"
import countries from "./countries"

export const useSubscribeOverlayStore = create((set) => ({
  title: "Want the latest tips, trends, and news about partnerships? Subscribe to the newsletter!",
  shouldShowSubscribeOverlay: false,
  setShouldShowSubscribeOverlay: (value) => set({ shouldShowSubscribeOverlay: value }),
  mktoFormId: "mktoForm_2376",
  form: {
    requiredText: "Required",
    invalidText: "Invalid",
    firstNamePlaceholder: "First name",
    lastNamePlaceholder: "Last name",
    companyNamePlaceholder: "Company name",
    jobTitlePlaceholder: "Job title",
    emailPlaceholder: "Email",
    countryPlaceholder: "Country",
    countries,
    submitButtonText: "Sign me up",
    submitSuccessText: "You'll be hearing from us soon.",
  },
}))
