export const countries = [
  { name: "", gdpr: true },
  { name: "United States", gdpr: false },
  { name: "Australia", gdpr: true },
  { name: "Canada", gdpr: true },
  { name: "United Kingdom", gdpr: true },
  { name: "Afghanistan", gdpr: true },
  { name: "Albania", gdpr: true },
  { name: "Andorra", gdpr: true },
  { name: "Antigua and Barbuda", gdpr: true },
  { name: "Argentina", gdpr: true },
  { name: "Armenia", gdpr: true },
  { name: "Austria", gdpr: true },
  { name: "Azerbaijan", gdpr: true },
  { name: "Bahamas", gdpr: true },
  { name: "Bahrain", gdpr: true },
  { name: "Bangladesh", gdpr: true },
  { name: "Barbados", gdpr: true },
  { name: "Belarus", gdpr: true },
  { name: "Belgium", gdpr: true },
  { name: "Belize", gdpr: true },
  { name: "Bhutan", gdpr: true },
  { name: "Bolivia", gdpr: true },
  { name: "Bosnia and Herzegovina", gdpr: true },
  { name: "Brazil", gdpr: true },
  { name: "Brunei", gdpr: true },
  { name: "Bulgaria", gdpr: true },
  { name: "Burma", gdpr: true },
  { name: "Cambodia", gdpr: true },
  { name: "Chile", gdpr: true },
  { name: "China", gdpr: true },
  { name: "Colombia", gdpr: true },
  { name: "Costa Rica", gdpr: true },
  { name: "Croatia", gdpr: true },
  { name: "Cyprus", gdpr: true },
  { name: "Czech Republic", gdpr: true },
  { name: "Denmark", gdpr: true },
  { name: "Dominica", gdpr: true },
  { name: "Dominican Republic", gdpr: true },
  { name: "Ecuador", gdpr: true },
  { name: "El Salvador", gdpr: true },
  { name: "England", gdpr: true },
  { name: "Estonia", gdpr: true },
  { name: "Fiji", gdpr: true },
  { name: "Finland", gdpr: true },
  { name: "France", gdpr: true },
  { name: "Georgia", gdpr: true },
  { name: "Germany", gdpr: true },
  { name: "Greece", gdpr: true },
  { name: "Grenada", gdpr: true },
  { name: "Guatemala", gdpr: true },
  { name: "Guyana", gdpr: true },
  { name: "Haiti", gdpr: true },
  { name: "Honduras", gdpr: true },
  { name: "Hungary", gdpr: true },
  { name: "Iceland", gdpr: true },
  { name: "India", gdpr: true },
  { name: "Indonesia", gdpr: true },
  { name: "Iraq", gdpr: true },
  { name: "Ireland", gdpr: true },
  { name: "Israel", gdpr: true },
  { name: "Italy", gdpr: true },
  { name: "Jamaica", gdpr: true },
  { name: "Japan", gdpr: true },
  { name: "Jordan", gdpr: true },
  { name: "Kazakhstan", gdpr: true },
  { name: "Kiribati", gdpr: true },
  { name: "Kosovo", gdpr: true },
  { name: "Kuwait", gdpr: true },
  { name: "Kyrgyzstan", gdpr: true },
  { name: "Laos", gdpr: true },
  { name: "Latvia", gdpr: true },
  { name: "Lebanon", gdpr: true },
  { name: "Liechtenstein", gdpr: true },
  { name: "Lithuania", gdpr: true },
  { name: "Luxembourg", gdpr: true },
  { name: "Macedonia (FYROM)", gdpr: true },
  { name: "Malaysia", gdpr: true },
  { name: "Maldives", gdpr: true },
  { name: "Malta", gdpr: true },
  { name: "Marshall Islands", gdpr: true },
  { name: "Mexico", gdpr: true },
  { name: "Micronesia", gdpr: true },
  { name: "Moldova", gdpr: true },
  { name: "Monaco", gdpr: true },
  { name: "Mongolia", gdpr: true },
  { name: "Montenegro", gdpr: true },
  { name: "Myanmar", gdpr: true },
  { name: "Nauru", gdpr: true },
  { name: "Nepal", gdpr: true },
  { name: "Netherlands", gdpr: true },
  { name: "New Zealand", gdpr: true },
  { name: "Nicaragua", gdpr: true },
  { name: "Norway", gdpr: true },
  { name: "Oman", gdpr: true },
  { name: "Pakistan", gdpr: true },
  { name: "Palau", gdpr: true },
  { name: "Palestine", gdpr: true },
  { name: "Panama", gdpr: true },
  { name: "Papua New Guinea", gdpr: true },
  { name: "Paraguay", gdpr: true },
  { name: "Peru", gdpr: true },
  { name: "Philippines", gdpr: true },
  { name: "Poland", gdpr: true },
  { name: "Portugal", gdpr: true },
  { name: "Qatar", gdpr: true },
  { name: "Romania", gdpr: true },
  { name: "Russia", gdpr: true },
  { name: "Saint Kitts and Nevis", gdpr: true },
  { name: "Saint Lucia", gdpr: true },
  { name: "Saint Vincent and the Grenadines", gdpr: true },
  { name: "Samoa", gdpr: true },
  { name: "San Marino", gdpr: true },
  { name: "Saudi Arabia", gdpr: true },
  { name: "Scotland", gdpr: true },
  { name: "Serbia", gdpr: true },
  { name: "Singapore", gdpr: true },
  { name: "Slovakia", gdpr: true },
  { name: "Slovenia", gdpr: true },
  { name: "Solomon Islands", gdpr: true },
  { name: "South Africa", gdpr: true },
  { name: "South Korea", gdpr: true },
  { name: "Spain", gdpr: true },
  { name: "Sri Lanka", gdpr: true },
  { name: "Suriname", gdpr: true },
  { name: "Sweden", gdpr: true },
  { name: "Switzerland", gdpr: true },
  { name: "Syria", gdpr: true },
  { name: "Taiwan", gdpr: true },
  { name: "Tajikistan", gdpr: true },
  { name: "Thailand", gdpr: true },
  { name: "Timor-Leste", gdpr: true },
  { name: "Tonga", gdpr: true },
  { name: "Trinidad and Tobago", gdpr: true },
  { name: "Turkey", gdpr: true },
  { name: "Turkmenistan", gdpr: true },
  { name: "Tuvalu", gdpr: true },
  { name: "Ukraine", gdpr: true },
  { name: "United Arab Emirates (UAE)", gdpr: true },
  { name: "Uruguay", gdpr: true },
  { name: "Uzbekistan", gdpr: true },
  { name: "Vanuatu", gdpr: true },
  { name: "Vatican City", gdpr: true },
  { name: "Venezuela", gdpr: true },
  { name: "Vietnam", gdpr: true },
  { name: "Yemen", gdpr: true },
]

export default countries
