import React, { useRef, useEffect, useCallback, useState } from "react"
import c from "classnames"
import { useMainStore } from "src/state/main"
import { getCssVar } from "src/utils"

export const Section = ({ className, style, children, render }) => {
  const sectionRef = useRef()
  const section = sectionRef.current
  const [, updateState] = useState()
  const forceUpdate = useCallback(() => updateState({}), [])
  const { scrollY, windowHeight, isBrowser } = useMainStore() // eslint-disable-line

  let marginTop = Number(getCssVar("--headerHeight").replace("px", ""))
  let offset = section ? section.getBoundingClientRect().top - marginTop : marginTop
  let visibility = 1 - offset / windowHeight

  let visibilityClamped = visibility
  if (visibilityClamped < 0) visibilityClamped = 0
  if (visibilityClamped > 1) visibilityClamped = 1

  let visibilityClampedAbs = 1 - Math.abs(offset) / windowHeight
  if (visibilityClampedAbs < 0) visibilityClampedAbs = 0
  if (visibilityClampedAbs > 1) visibilityClampedAbs = 1

  useEffect(() => {
    const observer = new ResizeObserver(forceUpdate)
    section && observer.observe(section)
    return () => section && observer.unobserve(section)
  }, [section, forceUpdate])

  return (
    <section
      ref={sectionRef}
      className={c(`section`, visibilityClampedAbs > 0.2 && "visible", className)}
      style={{
        ...style,
        "--offset": `${offset}px`,
        "--visibility": visibility,
        "--visibilityClamped": visibilityClamped,
        "--visibilityClampedAbs": visibilityClampedAbs,
        "--offsetAbs": Math.abs(offset),
      }}
    >
      {render ? render({ offset, visibility }) : children}
    </section>
  )
}

export default Section
