import React, { useState } from "react"
import c from "classnames"
import ReactScrollWheelHandler from "react-scroll-wheel-handler"
import Helmet from "react-helmet"
import Header from "src/components/header"
import PillButton from "src/components/pill-button"
import OrderMenu from "src/components/order-menu"
import PageBackground from "src/components/page-background"
import SubscribeOverlay from "src/components/subscribe-overlay"
import Footer from "src/components/footer"
import { useMainStore } from "src/state/main"
import { useOrderMenuStore } from "src/state/order-menu"

export const Page = ({
  id,
  title,
  className,
  style,
  children,
  totalSections = 0,
  withBackground = true,
}) => {
  const { introPlaying, metaUrl, introComplete, scrollPct, scrollY, orderStickyText } =
    useMainStore()
  const [sectionIndex, setSectionIndex] = useState(0)

  const { setShouldShowOrderMenu } = useOrderMenuStore()

  const prevSection = () => {
    let prevSectionIndex = sectionIndex - 1
    if (prevSectionIndex < 0) prevSectionIndex = 0
    setSectionIndex(prevSectionIndex)
  }

  const nextSection = () => {
    let nextSectionIndex = sectionIndex + 1
    if (nextSectionIndex < 0) nextSectionIndex = 0
    setSectionIndex(nextSectionIndex)
  }

  return (
    <ReactScrollWheelHandler upHandler={prevSection} downHandler={nextSection}>
      <div
        className={c(
          `page page-${id}`,
          !introPlaying && !introComplete && "before-intro",
          introPlaying && "intro-playing",
          introComplete && "intro-complete",
          className
        )}
        style={{
          ...style,
          "--sectionIndex": sectionIndex,
          "--scrollPct": scrollPct,
          "--scrollY": scrollY,
        }}
      >
        <Helmet>
          <title>{title}</title>
          <link rel="canonical" href={`${metaUrl}/${id}`} />
          <meta property="og:url" content={`${metaUrl}/${id}`} />
        </Helmet>
        {withBackground && <PageBackground />}
        <Header />
        <div className="page-content">{children}</div>
        <Footer />
        <PillButton onClick={() => setShouldShowOrderMenu(true)} className="order-sticky">
          {orderStickyText}
        </PillButton>
        <SubscribeOverlay />
        <OrderMenu />
      </div>
    </ReactScrollWheelHandler>
  )
}

export default Page
