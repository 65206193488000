import React from "react"
import c from "classnames"
import { greyCircle } from "src/components/svgs"
import Markup from "src/components/markup"
import { useMainStore } from "src/state/main"

const PageBackground = () => {
  const { scrollPct } = useMainStore()

  return (
    <div
      className="page-background"
      style={{
        "--scrollPct": scrollPct,
      }}
    >
      <div className={c("circle circle-1", scrollPct > 0.01 && scrollPct < 0.4 && "visible")}>
        <Markup>{greyCircle}</Markup>
      </div>
      <div className={c("circle circle-2", scrollPct > 0.15 && scrollPct < 0.3 && "visible")}>
        <Markup>{greyCircle}</Markup>
      </div>
      <div className={c("circle circle-3", scrollPct > 0.35 && scrollPct < 0.5 && "visible")}>
        <Markup>{greyCircle}</Markup>
      </div>
      <div className={c("circle circle-4", scrollPct > 0.3 && scrollPct < 0.7 && "visible")}>
        <Markup>{greyCircle}</Markup>
      </div>
      <div className={c("circle circle-5", scrollPct > 0.5 && scrollPct < 0.85 && "visible")}>
        <Markup>{greyCircle}</Markup>
      </div>
      <div className={c("circle circle-6", scrollPct > 0.7 && scrollPct < 1 && "visible")}>
        <Markup>{greyCircle}</Markup>
      </div>
    </div>
  )
}

export default PageBackground
