export const clamp = (num, min = 0, max = Infinity) => Math.min(Math.max(num, min), max)

export const clampMin = (num, min) => (num > min ? num : min)

export const clampMax = (num, max) => (num < max ? num : max)

export const getCssVar = (key, query = "html") => {
  if (typeof window === "undefined") return ""
  const node = document.querySelector(query)
  if (!node) return ""
  return getComputedStyle(node).getPropertyValue(key)
}
