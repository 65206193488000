import React from "react"
import c from "classnames"

export const PillButton = ({ className, children, ...rest }) => {
  return (
    <div
      onKeyDown={() => {}}
      role="button"
      tabIndex="0"
      className={c("pill-button", className)}
      {...rest}
    >
      {children}
    </div>
  )
}

export default PillButton
