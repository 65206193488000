import React, { Fragment } from "react"
import { useOrderMenuStore } from "src/state/order-menu"
import c from "classnames"
import { closeOrderMenuBtn } from "src/components/svgs"
import Markup from "src/components/markup"

const OrderMenu = () => {
  const { shouldShowOrderMenu, setShouldShowOrderMenu, title, orderButtons } = useOrderMenuStore()

  return (
    <div className={c("order-menu", shouldShowOrderMenu && "visible")}>
      <div
        className="bg"
        onClick={() => setShouldShowOrderMenu(false)}
        rel="noreferrer"
        tabIndex="0"
        role="button"
        aria-label="Open Menu"
        onKeyDown={() => {}}
      />
      <div className="menu">
        <div
          className="close-btn"
          onClick={() => setShouldShowOrderMenu(false)}
          rel="noreferrer"
          tabIndex="0"
          role="button"
          onKeyDown={() => {}}
        >
          <Markup>{closeOrderMenuBtn}</Markup>
        </div>
        <h3 className="title">{title}</h3>
        <div className="order-btns">
          {orderButtons.map(({ logo, url }, i) => (
            <Fragment key={i}>
              <a key={i} className="order-btn" href={url} target="_blank" rel="noreferrer">
                <Markup>{logo}</Markup>
              </a>
              <div className="border" />
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default OrderMenu
