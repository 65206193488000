import React from "react"
import { useFooterStore } from "src/state/footer"
import { useSubscribeOverlayStore } from "src/state/subscribe-overlay"
import PillButton from "src/components/pill-button"
import Markup from "src/components/markup"
import { Link } from "gatsby"

const Footer = () => {
  const { newsletterText, subscribeButtonText, contactText, privacyText, copyrightText, socials } =
    useFooterStore()
  const { setShouldShowSubscribeOverlay } = useSubscribeOverlayStore()

  return (
    <footer>
      <div className="border-top" />
      <div className="content">
        <div className="left">
          <p className="newsletter-copy">{newsletterText}</p>
          <PillButton
            onClick={() => setShouldShowSubscribeOverlay(true)}
            className="subscribe-btn black"
          >
            {subscribeButtonText}
          </PillButton>
        </div>
        <div className="right">
          <div className="links">
            <Link className="contact" to="/contact">
              <p>{contactText}</p>
            </Link>
            <Link className="privacy" to="/privacy">
              <p>{privacyText}</p>
            </Link>
            <div className="pipe" />
            <p className="copyright">{copyrightText}</p>
          </div>
          <div className="socials">
            {socials.map((social) => (
              <a key={social.url} href={social.url} target="_blank" rel="noreferrer">
                <Markup>{social.svg}</Markup>
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
