import React, { Fragment } from "react"
import { Link } from "gatsby"
import c from "classnames"
import { useHeaderStore } from "src/state/header"
import { useOrderMenuStore } from "src/state/order-menu"
import PillButton from "src/components/pill-button"
import { openMenuBtn, closeMenuBtn } from "src/components/svgs"
import Markup from "src/components/markup"

const Header = () => {
  const {
    logoText,
    buttonText,
    desktopLinks,
    mobileLinks,
    setShouldShowMobileMenu,
    shouldShowMobileMenu,
  } = useHeaderStore()

  const { setShouldShowOrderMenu } = useOrderMenuStore()

  return (
    <header>
      <div className="desktop">
        <div className="bg" />
        <Link to="/" className="logo-text">
          <p>{logoText}</p>
        </Link>
        <div className="links">
          {desktopLinks.map((link) => (
            <Link className="text-link" key={link.label} to={link.to}>
              {link.label}
            </Link>
          ))}
          <PillButton onClick={() => setShouldShowOrderMenu(true)} className="order-btn">
            {buttonText}
          </PillButton>
        </div>
      </div>
      <div className="mobile">
        <div className={c("mobile-menu", shouldShowMobileMenu && "visible")}>
          <div className="links">
            {mobileLinks.map((link) => (
              <Fragment key={link.label}>
                {link.borderTop && <div className="border-top" />}
                <Link className="text-link" key={link.label} to={link.to}>
                  {link.label}
                </Link>
              </Fragment>
            ))}
          </div>
        </div>
        <div className="menu-bar">
          <div className="bg" />
          <Link to="/" className="logo-text">
            <p>{logoText}</p>
          </Link>
          {shouldShowMobileMenu ? (
            <div
              className="close-btn"
              onClick={() => setShouldShowMobileMenu(false)}
              rel="noreferrer"
              tabIndex="0"
              role="button"
              onKeyDown={() => {}}
            >
              <Markup>{closeMenuBtn}</Markup>
            </div>
          ) : (
            <div
              className="open-btn"
              onClick={() => setShouldShowMobileMenu(true)}
              rel="noreferrer"
              tabIndex="0"
              role="button"
              onKeyDown={() => {}}
            >
              <Markup>{openMenuBtn}</Markup>
            </div>
          )}
        </div>
      </div>
    </header>
  )
}

export default Header
